<template>
  <div class="service-form-part">
    <ServiceField :init-with-value="serviceId" hidden v-on="$listeners" />

    <MarketSegmentRadioField :choices="marketSegments" v-on="$listeners" />

    <h2 class="form-modal__part-title">
      {{ $t('field.type-of-assignment') }}
    </h2>

    <CustomDimensionFormField :choices="choices" v-on="$listeners" />
  </div>
</template>

<script>
import CustomDimensionFormField from 'chimera/all/components/form/fields/customDimension/CustomDimensionSelectableField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import { homeBatteryNL } from 'chimera/homeBatteries/service'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField.vue'
import MarketSegmentRadioField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    MarketSegmentRadioField,
    ServiceField,
    CustomDimensionFormField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceId: homeBatteryNL.id,

      marketSegments: [
        new Selectable(
          this.$t('field.residential-building').toLowerCase(),
          this.$t('field.residential-building'),
          this.$t('field.marketSegment.consumer'),
        ),
        new Selectable(
          this.$t('field.business-premises').toLowerCase(),
          this.$t('field.business-premises'),
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          this.$t('field.agricultural-enterprise').toLowerCase(),
          this.$t('field.agricultural-enterprise'),
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          this.$t('field.large-business-requests').toLowerCase(),
          this.$t('field.large-business-requests'),
          this.$t('field.marketSegment.corporateValue'),
        ),
      ],

      choices: [
        new Selectable(
          this.$t('field.house-battery').toLowerCase(),
          this.$t('field.house-battery'),
          this.$t('field.house-battery'),
        ),
        new Selectable(
          this.$t('field.house-battery-and-solar-panels').toLowerCase(),
          this.$t('field.house-battery-and-solar-panels'),
          this.$t('field.house-battery-and-solar-panels'),
        ),
      ],
    }
  },
}
</script>
